@font-face {
    font-family: "Satoshi";
    src: local("Satoshi"),
        url("../public/assets/fonts/Satoshi-Variable.woff2") format("woff2"),
        url("../public/assets/fonts/Satoshi-Variable.woff") format("woff"),
        url("../public/assets/fonts/Satoshi-Variable.ttf") format("truetype");
    font-weight: 300 900;
    font-display: swap;
    font-style: normal;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    margin: 0;
    font-family: "Satoshi", -apple-system, BlinkMacSystemFont, "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    word-wrap: break-word;
    overflow-x: auto;
    white-space: pre-wrap;
}

svg {
    display: block;
}
